import * as THREE from 'three'
import Stats from 'stats.js'
import Leaf from './Leaf'

class App {
  LEAF_COUNT = 150

  constructor() {
    this.animate = this.animate.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  init() {
    // Setup scene
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0xf0f0f0)

    // Setup camera
    this.camera = new THREE.OrthographicCamera()

    // Setup renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    document.body.appendChild(this.renderer.domElement)

    // Setup clock
    this.clock = new THREE.Clock()

    // Adjust renderer and camera
    this.onResize()
    window.addEventListener('resize', this.onResize)

    this.setupLeafs()

    // Setup stats.js
    this.stats = new Stats()
    document.body.appendChild(this.stats.dom)
  }

  setupLeafs() {
    this.leafs = []

    for (let i = 0; i < this.LEAF_COUNT; i++) {
      const leaf = new Leaf()

      this.leafs.push(leaf)
      this.scene.add(leaf.object)
    }
  }

  animate() {
    requestAnimationFrame(this.animate)

    const delta = this.clock.getDelta()
    for (const leaf of this.leafs) {
      leaf.updateTransition(this.viewportWidth, this.viewportHeight, delta)
    }

    this.renderer.render(this.scene, this.camera)

    this.stats.update()
  }

  onResize() {
    const width = window.innerWidth
    const height = window.innerHeight
    const aspect = window.innerWidth / window.innerHeight

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)

    const frustumSize = 1000
    this.viewportWidth = frustumSize * aspect
    this.viewportHeight = frustumSize

    this.camera.left = 0
    this.camera.right = this.viewportWidth
    this.camera.top = 0
    this.camera.bottom = this.viewportHeight
    this.camera.near = 0
    this.camera.far = 1000

    this.camera.updateProjectionMatrix()
  }
}

export default App
