import * as THREE from 'three'

class Leaf {
  static LeafShape = new THREE.Shape()
      .moveTo(12.8, 0)
      .bezierCurveTo(9.73, 4.11, 4.11, 9.97, 4.11, 16.9)
      .bezierCurveTo(4.11, 21.71, 8, 25.6, 12.8, 25.6)
      .bezierCurveTo(17.6, 25.6, 21.49, 21.71, 21.49, 16.9)
      .bezierCurveTo(21.49, 9.97, 15.87, 4.11, 12.8, 0)

  static LeafGeometry = new THREE.ShapeBufferGeometry(Leaf.LeafShape)

  constructor() {
    this.object = new THREE.Mesh(
      Leaf.LeafGeometry,
      new THREE.MeshBasicMaterial({
        color: Math.random() * 0xffffff,
        side: THREE.DoubleSide
      })
    )
    this.object.position.z = -500

    this.start = {
      position: {
        x: Math.random(),
        y: -0.1
      },
      rotation: {
        x: 2 * Math.PI * Math.random(),
        y: 2 * Math.PI * Math.random(),
        z: 2 * Math.PI * Math.random()
      }
    }
    this.goal = {
      position: {
        x: this.start.position.x + (0.4 * Math.random() - 0.2),
        y: 1.1
      },
      rotation: {
        x: 2 * Math.PI * Math.random(),
        y: 2 * Math.PI * Math.random(),
        z: 2 * Math.PI * Math.random()
      }
    }

    this.loopDuration = 4 + Math.random()
    this.elapsed = this.loopDuration * Math.random()
  }

  updateTransition(viewportWidth, viewportHeight, timeDelta) {
    this.elapsed += timeDelta
    this.elapsed %= this.loopDuration

    this.loopProgress = this.elapsed / this.loopDuration

    this.object.position.x =
      viewportWidth *
      (this.start.position.x +
        (this.goal.position.x - this.start.position.x) * this.loopProgress)

    this.object.position.y =
      viewportHeight *
      (this.start.position.y +
        (this.goal.position.y - this.start.position.y) * this.loopProgress)

    this.object.rotation.x =
      this.start.rotation.x +
      (this.goal.rotation.x - this.start.rotation.x) * this.loopProgress

    this.object.rotation.y =
      this.start.rotation.y +
      (this.goal.rotation.y - this.start.rotation.y) * this.loopProgress

    this.object.rotation.z =
      this.start.rotation.z +
      (this.goal.rotation.z - this.start.rotation.z) * this.loopProgress
  }
}

export default Leaf
